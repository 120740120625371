import { useState } from 'react';

import type { FC } from 'react';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';
import { Anchor, Box, Button, Popover, Select, Textarea, TFNotifier, TFText } from '@tf/ui';

export const HaveIssuesPopover: FC = () => {
	const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [category, setCategory] = useState<BackendTypes.FeedbackIssueCategory>('GENERAL');
	const [description, setDescription] = useState('');

	const sendFeedback = async (): Promise<void> => {
		try {
			setIsSubmitting(true);
			await BackendClient.getInstance().feedbackIssue({
				category,
				// Replace special characters as their presence break request to Linear
				description: `${description}  Sent from: ${window.location.href}`.replaceAll(/[\n\r\t]/g, ' '),
			});
			TFNotifier.info('Thank you for your feedback! We will get back to you soon.');
			setIsFeedbackOpen(false);
			setCategory('GENERAL');
			setDescription('');
		} catch (e) {
			TFNotifier.error('Something went wrong. Please try again later.');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Popover
			width={400}
			opened={isFeedbackOpen}
			onClose={() => setIsFeedbackOpen(false)}
			trapFocus
			position="bottom"
			withArrow
			withinPortal
			shadow="md"
		>
			<Popover.Target>
				<Button
					size="xs"
					variant="outline"
					color="black"
					mr="1rem"
					onClick={() => setIsFeedbackOpen(!isFeedbackOpen)}
					sx={({ colors }) => ({
						fontWeight: 400,
						color: colors.gray[7],
						borderColor: colors.gray[3],
					})}
				>
					Have issues?
				</Button>
			</Popover.Target>
			<Popover.Dropdown
				sx={(theme) => ({
					background: theme.white,
				})}
			>
				<Select
					value={category}
					disabled={isSubmitting}
					data-readonly={false}
					comboboxProps={{ withinPortal: false }}
					onChange={(value) => setCategory((value as BackendTypes.FeedbackIssueCategory) || 'GENERAL')}
					data={[
						{ value: 'GENERAL', label: 'General issues' },
						{ value: 'MIGRATION', label: 'Migration issues' },
						{ value: 'OTHER', label: 'Other issues' },
					]}
					mb=".75rem"
				/>
				<Textarea
					labelProps={{ size: 'xs' }}
					minRows={3}
					maxRows={10}
					value={description}
					onChange={(e) => setDescription(e.currentTarget.value)}
					disabled={isSubmitting}
					autosize
					placeholder="Please describe your issue"
				/>
				<TFText size="xs" mt=".5rem">
					Or send email to <Anchor href="mailto:support@trustform.io">support@trustform.io</Anchor>
				</TFText>
				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button
						fullWidth
						variant="light"
						size="sm"
						mr=".75rem"
						onClick={() => setIsFeedbackOpen(false)}
					>
						Cancel
					</Button>
					<Button
						loading={isSubmitting}
						fullWidth
						size="sm"
						disabled={description.length < 3}
						loaderProps={{ size: 14 }}
						onClick={sendFeedback}
					>
						Send feedback
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
