import { Group, Stack, TFCard } from '@tf/ui';

import { AccountEventLog, AccountSettings, AccountTasks, AccountToolbar } from '@/components/accounts';

export const AccountOverview = () => {
	return (
		<Stack py="0.75rem" gap="md">
			<AccountToolbar />

			<Group gap="md" align="start" grow>
				<Stack>
					<TFCard title="Tasks">
						<AccountTasks />
					</TFCard>
					<TFCard title="Event log">
						<AccountEventLog />
					</TFCard>
				</Stack>

				<TFCard title="Account information">
					<AccountSettings />
				</TFCard>
			</Group>
		</Stack>
	);
};
